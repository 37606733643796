@font-face {
  font-family: "Futura Light";
  src: local("Futura New Light Reg"), local("Futura-New-Light-Reg"), url("../assets/fonts/FuturaNewLight-Reg.woff2") format("woff2"), url("../assets/fonts/FuturaNewLight-Reg.woff") format("woff"), url("../assets/fonts/FuturaNewLight-Reg.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura Book";
  src: local("Futura New Book Reg"), local("Futura-New-Book-Reg"), url("../assets/fonts/FuturaNewBook-Reg.woff2") format("woff2"), url("../assets/fonts/FuturaNewBook-Reg.woff") format("woff"), url("../assets/fonts/FuturaNewBook-Reg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura Medium";
  src: local("Futura New Medium Reg"), local("Futura-New-Medium-Reg"), url("../assets/fonts/FuturaNewMedium-Reg.woff2") format("woff2"), url("../assets/fonts/FuturaNewMedium-Reg.woff") format("woff"), url("../assets/fonts/FuturaNewMedium-Reg.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
html {
  scroll-behavior: smooth;
}

h1, h2 {
  @apply font-title text-base desktop:text-md mb-3 desktop:mb-5;
}

p, span {
  @apply font-text text-base my-1 desktop:my-3;
}

/*# sourceMappingURL=styles.css.map */
